.textBox {
  padding: 10px;
  background-color: white;
  margin: auto;
  margin-top: 10px;
  max-width: 1000px;
  h3 {
    text-align: center;
  }
  ol {
    counter-reset: item;
  }
  li {
    display: block;
  }
  li::before {
    content: counters(item, '.') ' ';
    counter-increment: item;
  }
}
