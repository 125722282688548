@import 'antd/dist/antd.compact.css';
.ant-layout-header {
  background-color: white;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.mainfooter {
  padding: 10px;
  gap: 10px 10px;
  justify-content: space-around;
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: white;
  margin-top: 20px;
}
