.S {
  background-color: #d5e4ed;
  color: #000;
  font-weight: 900;
}

.A {
}

.TM {
  background-color: #ebf5fa;
  font-weight: bold;
}
