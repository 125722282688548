.extratoprint {
  table {
    border-collapse: collapse;
    thead {
      tr {
        th {
          padding: 5px;
          white-space: nowrap;
          border: 1px solid lightgrey;
          text-align: left;
          &.valor {
            text-align: right;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          text-align: left;
          padding: 5px;
          white-space: nowrap;
          border: 1px solid lightgrey;
          &.valor {
            text-align: right;
          }
          &.negativo {
            color: red;
          }
          &.positivo {
            color: blue;
          }
        }
      }
    }
    tfoot {
      tr {
        td {
          padding: 5px;
          border: 1px solid lightgrey;
          white-space: nowrap;
        }
      }
    }
  }
}
