.fadeLeft {
  overflow: hidden;
  transform: translateX(-300px);
  opacity: 0.1;
  transition: 1s;
  &.visible {
    opacity: 1;
    transform: translateX(0);
  }
}
.fadeRight {
  overflow: hidden;
  transform: translateX(300px);
  opacity: 0.1;
  transition: 1s;
  &.visible {
    opacity: 1;
    transform: translateX(0);
  }
}
