.homeLayout {
  min-width: 250px;
  width: 100%;
  max-width: 800px;
}
.session {
  display: flex;
  flex-direction: column;
  gap: 10px 10px;
  margin-top: 20px;
  margin-bottom: 20px;

  .image {
    flex: 1 1 350px;
    height: auto;
    width: 100%;
    img {
      height: auto;
      width: 100%;
      display: block;
      box-shadow: 2px 2px 5px lightgrey;
    }
  }
  .body {
    flex: 1 0 100px;
    padding: 10px;
    background-color: #12496d;
    color: white;
    .title {
      font-size: 18px;
      font-weight: bold;
    }
    .text {
      font-size: 14px;
    }
  }
}
.ant-carousel {
  margin-top: 20px;
  margin-bottom: 20px;
  .slick-slider {
    .slick-list {
      .slick-slide {
        .carousel-img {
          img {
            width: 100%;
            object-fit: scale-down;
          }
          .title {
            font-size: 16px;
            text-align: center;
            background-color: #12496d;
            color: white;
          }
        }
      }
    }
  }
}
.box {
  margin-top: 20px;
  color: white;
  display: flex;
  background-color: #12496d;
  padding: 10px;
  align-items: center;
  flex-direction: column-reverse;
  gap: 10px;
  .image {
    flex: 1 1 200px;
    img {
      max-width: 100%;
      height: auto;
      filter: brightness(10000%);
    }
  }
  .content {
    display: flex;
    gap: 10px;
    text-align: justify;
    flex-direction: column;
    flex: 1 0 200px;
    .title {
      font-size: 22px;
      font-weight: bold;
    }
    .description {
      font-size: 14px;
    }
  }
}
