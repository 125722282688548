.balancoprint {
  table {
    border-collapse: collapse;
    thead {
      tr {
        th {
          padding: 5px;
          white-space: nowrap;
          border: 1px solid lightgrey;
          text-align: left;
          &.valor {
            text-align: right;
          }
        }
      }
    }
    tbody {
      tr {
        &.nivel1 {
          background-color: #d5e4ed;
          font-weight: 900;
        }
        &.nivel2 {
          background-color: #ebf5fa;
          text-indent: 10px;
          font-weight: 600;
        }
        &.nivel3 {
          text-indent: 20px;
          font-weight: 600;
        }
        &.nivel4 {
          text-indent: 30px;
        }
        &.nivel5 {
          font-style: italic;
          text-indent: 40px;
        }
        &.nivel6 {
          font-style: italic;
          text-indent: 50px;
        }
        &.inivel1 {
          background-color: #d5e4ed;
          font-weight: 900;
        }
        &.inivel2 {
          background-color: #ebf5fa;
          font-weight: 600;
        }
        &.inivel3 {
          font-weight: 600;
        }
        &.inivel4 {
        }
        &.inivel5 {
          font-style: italic;
        }
        td {
          text-align: left;
          padding: 5px;
          white-space: nowrap;
          border: 1px solid lightgrey;
          &.valor {
            text-align: right;
          }
          &.negativo {
            color: red;
          }
          &.positivo {
            color: blue;
          }
        }
      }
    }
  }
}
