.fluxoprint {
  .total {
    background-color: #d5e4ed;
    color: #000;
    font-weight: 600;
    font-size: smaller;
  }

  .subtotal {
    background-color: #ebf5fa;
    font-weight: 400;
    font-size: smaller;
  }

  .conta {
    font-size: smaller;
    font-weight: 300;
  }
  td {
    padding: 3px;
    border: 1px solid lightgrey;
  }
  th {
    padding: 3px;
    border: 1px solid lightgrey;
  }
  .analitica {
    font-size: smaller;
    font-style: italic;
  }

  .nivel5 {
    font-style: italic;
    text-indent: 40px;
  }

  .nivel6 {
    font-style: italic;
    text-indent: 50px;
  }
  .valor {
    text-align: right;
  }
}
