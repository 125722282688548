.printmensaltable {
  td {
    border: 1px solid lightgrey;
    white-space: nowrap;
    padding: 3px;
    width: 100px;
  }
  .valor {
    text-align: right;
  }
  th {
    &.valor {
      text-align: right;
    }
    width: 100px;
    font-weight: bold;
    border: 1px solid lightgrey;
    padding: 3px;
  }
}
