.DREtable {
  tr {
    &.nivel1 {
      background-color: #d5e4ed;
      font-weight: 900;
    }
    &.nivel2 {
      background-color: #ebf5fa;
      text-indent: 10px;
      font-weight: 600;
    }
    &.nivel3 {
      text-indent: 20px;
      font-weight: 600;
    }
    &.nivel4 {
      text-indent: 30px;
      font-style: italic;
    }
  }
}

.dreprinttable {
  tr {
    &.nivel1 {
      background-color: #d5e4ed;
      font-weight: 900;
    }
    &.nivel2 {
      background-color: #ebf5fa;
      text-indent: 10px;
      font-weight: 600;
    }
    &.nivel3 {
      text-indent: 20px;
      font-weight: 600;
    }
    &.nivel4 {
      text-indent: 30px;
      font-style: italic;
    }
    td {
      &.valor {
        text-align: right;
      }
      border: 1px solid lightgrey;
      white-space: nowrap;
    }
    th {
      &.valor {
        text-align: right;
      }
      border: 1px solid lightgrey;
    }
  }
}
